export default {
	methods: {
		buildStringDate(rawDate) {
			let strEvtDate;
			let eventDate = new Date(rawDate);
			let eventDay = eventDate.getDate().toString();
			let eventMonth = (eventDate.getMonth() + 1).toString();
			let eventYear = eventDate.getFullYear().toString();
			let eventHour = eventDate.getHours().toString();
			let eventMinutes = eventDate.getUTCMinutes();
			let eventMeridian = eventHour >= 12 ? " PM" : " AM";

			eventDay = eventDay < 10 ? "0" + eventDay : eventDay;
			eventMonth = eventMonth < 10 ? "0" + eventMonth : eventMonth;
			eventMinutes = eventMinutes < 10 ? "0" + eventMinutes : eventMinutes;

			strEvtDate =
				eventDay + "/" + eventMonth + "/" + eventYear + " " + eventHour + ":" + eventMinutes + eventMeridian;

			return strEvtDate;
		},
		capitalizeFirstLetter(stringParam) {
			return stringParam.charAt(0).toUpperCase() + stringParam.slice(1);
		},
		getFirstWordAtKeyword(stringParam, keyword) {
			if (stringParam !== null && stringParam !== undefined) {
				let slicedString = stringParam.substr(0, stringParam.indexOf(keyword));
				return slicedString;
			} else {
				return "";
			}
		},
		getCharsAfterKeyword(stringParam, keyword) {
			if (stringParam !== null && stringParam !== undefined) {
				let charsAfter = stringParam.split(keyword)[1];
				return charsAfter;
			} else {
				return "";
			}
		},
		getCharsBeforeKeyword(stringParam, keyword) {
			if (stringParam !== null && stringParam !== undefined) {
				let charsBefore = stringParam.split(keyword)[0];
				return charsBefore;
			} else {
				return "";
			}
		},
		isJson(item) {
			item = typeof item !== "string" ? JSON.stringify(item) : item;

			try {
				item = JSON.parse(item);
			} catch (e) {
				return false;
			}

			if (typeof item === "object" && item !== null) {
				return true;
			}

			return false;
		},
	},
};
