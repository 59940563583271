/* eslint-disable no-mixed-spaces-and-tabs */
<template>
  <v-app>
    <v-main>
      <!-- <HeaderPage /> -->
      <BodyPage />
      <v-footer class="footerSite" app>
        <span>
          <a class="text-caption" style="" href="https://rayoapp.com"
            >Thor Seguimiento - v2.9 <v-icon>mdi-lock</v-icon>
          </a>
          <!-- <svg-icon type="mdi" :path="lock-outline"></svg-icon> -->
        </span>
      </v-footer>
    </v-main>
  </v-app>
</template>

<script>
import BodyPage from "./components/BodyPage";
// import HeaderPage from "./components/HeaderPage";

export default {
  name: "Seguimiento",
  components: {
    BodyPage,
    // HeaderPage,
  },

  data: () => ({
    //
  }),
};
</script>
