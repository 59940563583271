import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import VueRouter from "vue-router";
//import axios from "axios";
import FormatFunctions from "./mixins/FormatFunctions";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import "../src/assets/rayostyles.css";
import router from "./router";
import VueExpandableImage from 'vue-expandable-image'

//axios.defaults.baseURL = "https://api.tookanapp.com";

Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(VueExpandableImage)

Vue.mixin(FormatFunctions); //import various function to all components

new Vue({
	vuetify,
	router,
	render: (h) => h(App)
}).$mount("#app");
