import Vue from "vue";
import VueRouter from "vue-router";
import Seguimiento from "../App.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "",
    component: Seguimiento,
  },

  {
    path: "/El%20Brindis",
    name: "ELBRINDIS",
    component: Seguimiento,
  },

  {
    path: "/Alsea",
    name: "Alsea",
    component: Seguimiento,
  },
  {
    path: "/BurgerKing",
    name: "BurgerKing",
    component: Seguimiento,
  },
  {
    path: "/Melt",
    name: "Melt",
    component: Seguimiento,
  },
  {
    path: "/Bata",
    name: "Bata",
    component: Seguimiento,
  },
  {
    path: "/Aloprint",
    name: "Aloprint",
    component: Seguimiento,
  },
  {
    path: "/Forus",
    name: "Forus",
    component: Seguimiento,
  },
  {
    path: "/CruzVerde",
    name: "CruzVerde",
    component: Seguimiento,
  },
  {
    path: "/Claro",
    name: "Claro",
    component: Seguimiento,
  },

  {
    path: "/2bak",
    name: "2bak",
    component: Seguimiento,
  },

  {
    path: "/travel",
    name: "travel",
    component: Seguimiento,
  },

  {
    path: "/Degasa",
    name: "Degasa",
    component: Seguimiento,
  },
  {
    path: "/Maicao",
    name: "Maicao",
    component: Seguimiento,
  },
  {
    path: "/Wom",
    name: "wom",
    component: Seguimiento,
  },
  {
    path: "/Consulta",
    name: "Consulta",
    component: Seguimiento,
  },
  {
    path: "/Enviame",
    name: "Enviame",
    component: Seguimiento,
  },
  {
    path: "/Transbank",
    name: "Transbank",
    component: Seguimiento,
  },
  // {
  //   path: "/AlmacenGuru",
  //   name: "ALMACENGURU",
  //   component: Seguimiento,
  // },
  // {
  //   path: "/ALMACÉN%20GURÚ",
  //   name: "ALMACÉN%20GURÚ",
  //   component: Seguimiento,
  // },
  {
    path: "/Liquidos.cl",
    name: "LIQUIDOS.CL",
    component: Seguimiento,
  },

  {
    path: "/Mundo",
    name: "Mundo",
    component: Seguimiento,
  },

  {
    path: "/Corona",
    name: "Corona",
    component: Seguimiento,
  },

  {
    path: "/Unimarc",
    name: "Unimarc",
    component: Seguimiento,
  },

  {
    path: "/DAC",
    name: "DAC",
    component: Seguimiento,
  },
  // {
  //   path: "/DAC%20Cordillera",
  //   name: "DAC",
  //   component: Seguimiento,
  // },
  {
    path: "/BEES",
    name: "BEES",
    component: Seguimiento,
  },
  {
    path: "/DAC%20-%20MAGIC%20SALE",
    name: "DAC - MAGIC SALE",
    component: Seguimiento,
  },
  {
    path: "/DAC%20-%20VECTOR%20GROUP",
    name: "DAC - VECTOR GROUP",
    component: Seguimiento,
  },
  {
    path: "/DAC%20DC%20DIGITAL%20GROUP",
    name: "DAC DC DIGITAL GROUP",
    component: Seguimiento,
  },
  {
    path: "/DAC%20GEOBPO",
    name: "DAC GEOBPO",
    component: Seguimiento,
  },

  {
    path: "/DAC%20REDCEL",
    name: "DAC REDCEL",
    component: Seguimiento,
  },

  {
    path: "/Enviame%20Mexico",
    name: "Enviame Mexico",
    component: Seguimiento,
  },

  {
    path: "/petco_mex",
    name: "petco_mex",
    component: Seguimiento,
  },

  {
    path: "/petcoCL",
    name: "petcoCL",
    component: Seguimiento,
  },

  {
    path: "/ink-Chile",
    name: "ink-Chile",
    component: Seguimiento,
  },

  {
    path: "/UnimarcMFC",
    name: "UnimarcMFC",
    component: Seguimiento,
  },

  {
    path: "/transbankInst",
    name: "transbankInst",
    component: Seguimiento,
  },

  {
    path: "/Mirax%20instituciones",
    name: "MIRAXINST",
    component: Seguimiento,
  },
  {
    path: "/Store%20Central",
    name: "STORECENTRAL",
    component: Seguimiento,
  },

  {
    path: "/mirax",
    name: "MIRAX",
    component: Seguimiento,
  },

  {
    path: "/colgram_test",
    name: "COLGRAM_TEST",
    component: Seguimiento,
  },

  {
    path: "/colgram",
    name: "COLGRAM",
    component: Seguimiento,
  },

  {
    path: "/Junaeb",
    name: "Junaeb",
    component: Seguimiento,
  },

  {
    path: "/migps",
    name: "MIGPS",
    component: Seguimiento,
  },

  {
    path: "/sendu",
    name: "SENDU",
    component: Seguimiento,
  },

  {
    path: "/jawi",
    name: "JAWI",
    component: Seguimiento,
  },

  {
    path: "/BBVA%20Mexico",
    name: "BBVAMX",
    component: Seguimiento,
  },

  {
    path: "/farmaloop",
    name: "FARMALOOP",
    component: Seguimiento,
  },

  {
    path: "/coronasfs",
    name: "CORONASFS",
    component: Seguimiento,
  },

  {
    path: "/2bakTest",
    name: "2bakTest",
    component: Seguimiento,
  },

  {
    path: "/virgin",
    name: "VIRGIN",
    component: Seguimiento,
  },

  {
    path: "/belcorp",
    name: "BELCORP",
    component: Seguimiento,
  },
  {
    path: "/belcorp-test",
    name: "BELCORP-TEST",
    component: Seguimiento,
  },

  {
    path: "/bat",
    name: "BAT",
    component: Seguimiento,
  },
  {
    path: "/liola",
    name: "LIOLA",
    component: Seguimiento,
  },
  {
    path: "/femsaqa",
    name: "FEMSAQA",
    component: Seguimiento,
  },
  {
    path: "/yza",
    name: "YZA",
    component: Seguimiento,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
